import React from 'react'
import Container from './container'
import styles from './footer.module.css'
import { FaInstagram } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';

export default ({ data }) => (
  <div className={`wrapper ${styles.footer}`}>
    {/*
    <div className={styles.footerIcon}>
      <a href={`mailto:${data.email}`} >
        <GoMail size="35px" />
      </a>
    </div>
    */}
    <div className={styles.footerIcon}>
      <a href={`https://instagram.com/${data.instagram}`} className="hover-anim">
        <FaInstagram size="30px" />
      </a>
    </div>
  </div>
)