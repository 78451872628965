import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

export default () => (
  <div>
    <nav role="navigation">
      <ul className={styles.navigation}>
        <li className={styles.navigationItem}>
          <Link to="/paint/">Paint</Link>
        </li>
        <li className={styles.navigationItem}>
          <Link to="/wall/">Wall</Link>
        </li>
        <li className={styles.navigationItem}>
          <Link to="/play/">Play</Link>
        </li>
        <li className={styles.navigationItem}>
          <Link to="/connect/">Connect</Link>
        </li>     
        <li className={styles.navigationItem}>
          <Link to="/shop/">Shop</Link>
        </li>  
      </ul>
    </nav>
  </div>
)
