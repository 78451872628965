import React from 'react'
import Img from 'gatsby-image'
import Container from './container'
import styles from './header.module.css'
import Navigation from './navigation'
import { Link } from 'gatsby'


export default class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      expanded: true
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(e) {
    const { expanded } = this.state
    if(window.scrollY > 50) {
      if(expanded) {
        this.setState({ expanded: false })
      }
    } else {
      if(!expanded) {
        this.setState({ expanded: true })
      }
    }
  }

  render() {
    const { data } = this.props
    const { expanded } = this.state
    return (
      <div>
        <div className={expanded ? styles.fixedHeader : styles.fixedHeaderThin}>
          <Container>
            <div className={expanded ? styles.hero : styles.heroThin}>
              <h1 className={expanded ? styles.heroTitle : styles.heroTitleThin}>
                <Link to="/">
                  {data.name}
                </Link>
              </h1>
            </div>
          </Container>
          <Container>
            <Navigation/>
          </Container>
        </div>
        <div className={styles.fixedHeaderPlaceholder}>

        </div>
      </div>
    )

  }
}
