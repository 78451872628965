import _ from 'lodash'

const cloudinaryTransforms = 'c_fit,e_sharpen:200,h_500,w_500,q_80'

const presets = {
  'project_cover': 'c_thumb,e_sharpen:100,h_400,w_400,q_80',
  'project_image': 'c_fit,e_sharpen:100,h_1440,w_1440,q_80',
  'carousel': 'c_fit,e_sharpen:200,h_1440,w_1920,q_80',
  'carousel_mobile': 'c_fit,e_sharpen:200,h_768,w_768,q_80',
  'news_thumb': 'c_thumb,e_sharpen:200,h_270,w_480,q_80',
  'og_image': 'c_thumb,e_sharpen:100,h_630,w_1200,q_80',
  'lightbox_limit': 'c_fit,e_sharpen:100,h_2000,w_2000,q_80',
}

const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
}

export const addCloudinaryTransformations = (resource_url, transformation)=>{
  const splitURL = resource_url.split('/');
  splitURL.splice( 6, 0, transformation);
  const transformedURL = splitURL.join('/');
  return transformedURL;
      
}

export const addCloudinaryTransformPreset = (resource_url, presetName)=>{
  const splitURL = resource_url.split('/');
  if(presets[presetName]) {
    splitURL.splice( 6, 0, presets[presetName]);
  } 

  const transformedURL = splitURL.join('/');
  return transformedURL;
      
}

export const mergeCloudinaryContext = (mediaArray, cloudinaryArray, options = {}) => {
  let mediaDict = {}
  const defaultTransform = options.defaultTransform
  cloudinaryArray.forEach(item => {
    mediaDict[item.node.public_id] = item.node
  })
  return mediaArray.map(item => {
    let context = null
    const srcMedia = mediaDict[item.public_id]
    let title = _.get(srcMedia, 'context.custom.caption', null)
    let srcMediaUrl = _.get(srcMedia, 'secure_url', null)
    if(srcMediaUrl && defaultTransform) {
      srcMediaUrl = addCloudinaryTransformations(srcMediaUrl, defaultTransform)
    }
    let description = _.get(srcMedia, 'context.custom.alt', null)
    if(description) {
      description = replaceAll(description, '<br/>', '\n')
      description = replaceAll(description, '<br />', '\n')
    }
    return {  
              ...item,
              ...srcMedia, 
              title, 
              description, 
              secure_url: item.secure_url ? item.secure_url : srcMediaUrl
            }
  })
}

export const preprocessGalleryMedia = (mediaArray, cloudinaryArray, options = {}) => {
  let mediaDict = {}
  // The default transform applied to all images when displayed in the lightbox
  const defaultTransform = options.defaultTransform

  const defaultThumbTransform = options.defaultThumbTransform
  const sizeVariantThumbTransform = options.sizeVariantThumbTransform

  cloudinaryArray.forEach(item => {
    mediaDict[item.node.public_id] = item.node
  })
  return mediaArray.map(item => {
    let context = null
    const srcMedia = mediaDict[item.public_id]
    let title = _.get(srcMedia, 'context.custom.caption', null)
    let srcMediaUrl = _.get(srcMedia, 'secure_url', null)
    let mediaUrl = null
    let mediaThumbUrl = null 

    if(srcMediaUrl) {
      if(defaultTransform) {
        mediaUrl = addCloudinaryTransformations(srcMediaUrl, defaultTransform)
      }
      if(item.transform) {
        mediaUrl = addCloudinaryTransformations(srcMediaUrl, item.transform)
      }
      if(defaultThumbTransform) {
        mediaThumbUrl = addCloudinaryTransformations(srcMediaUrl, defaultThumbTransform)
      }
      if(sizeVariantThumbTransform) {
        const maxSize = Math.max(item.colspan || 1, item.rowspan || 1)
        if(maxSize - 1 < sizeVariantThumbTransform.length) {
          mediaThumbUrl = addCloudinaryTransformations(srcMediaUrl, sizeVariantThumbTransform[maxSize - 1])
        }

      }
      if(item.thumb_transform) {
        mediaThumbUrl = addCloudinaryTransformations(srcMediaUrl, item.thumb_transform)
      }
    }


    let description = _.get(srcMedia, 'context.custom.alt', null)
    if(description) {
      description = replaceAll(description, '<br/>', '\n')
      description = replaceAll(description, '<br />', '\n')
    }
    return {  
              ...item,
              ...srcMedia, 
              title, 
              description, 
              secure_thumb_url: mediaThumbUrl,
              // secure_url: item.secure_url ? item.secure_url : srcMediaUrl
              secure_url: mediaUrl ? mediaUrl : (item.secure_url ? item.secure_url : srcMediaUrl)
            }
  })
}